import React from "react"
import { graphql } from "gatsby"

import { AngeboteDesHausesTempl } from "../../pageTemplates/angebote-des-hauses"

const AngeboteDesHauses = ({ data, location }) => (
  <AngeboteDesHausesTempl data={data} location={location} en />
)

export const query = graphql`
  query {
    strapi {
      angeboteDesHause {
        meta_en {
          title
          description
        }
        inhalt_en {
          __typename
          ... on STRAPI_ComponentPageArticle {
            id
            article {
              text_en
              title_en
              subtitle_en
              use_alternative_font
              cover {
                __typename
                url
                caption
                alternativeText
                imageFileGray: imageFile {
                  childImageSharp {
                    fluid(
                      duotone: { highlight: "#ffffff", shadow: "#007c60" }
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
              gallery {
                url
                caption
                alternativeText
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageText {
            id
            text
          }
          ... on STRAPI_ComponentPageInformation {
            id
            text
            title
            __typename
            image {
              url
              caption
              alternativeText
              imageFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AngeboteDesHauses
